import { render, staticRenderFns } from "./FinishedRounds.vue?vue&type=template&id=095197b5&scoped=true&"
import script from "./FinishedRounds.vue?vue&type=script&lang=js&"
export * from "./FinishedRounds.vue?vue&type=script&lang=js&"
import style0 from "./FinishedRounds.vue?vue&type=style&index=0&id=095197b5&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "095197b5",
  null
  
)

export default component.exports